module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-matomo@0.16.3_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.9.1_jiti@1.21.6___reac_osh6qg3pt3rmuryxqlqh2bj7mq/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"3","matomoUrl":"https://analytics.huey.xyz","siteUrl":"https://clerkent.huey.xyz","cookieDomain":"*.clerkent.huey.xyz"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.9.1_jiti@1.21.6___re_ywx3vwi3rpj42zbxgleneoc3pa/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/clerkent.png","name":"Clerkent","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"750c700ba0c5adf03fdaadaeee71256c"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.9.1_jiti@1.21.6___react-dom@18.3.1_react@18.3.1__r_chazl37etbzxk64upso3ikxf6a/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
